<template>
    <div class="make-comment-input">
        <div class="make-comment-input__form" :class="{ 'grayscale filter': replying }">
            <div v-if="user" class="make-comment-input__icon">
                <img
                    v-if="user.image && user.image.url"
                    :src="user.image.url"
                    alt="imagen del usuario"
                    class="rounded-full object-cover"
                    width="10px"
                    height="10px"
                />
                <MiscUserPic v-else :user="user" :badge="false" class="h-10 w-10" />
            </div>
            <div v-else class="make-comment-input__icon">
                <img
                    :src="$assets.gray.userHeader"
                    alt="imagen del usuario"
                    class="object-contain p-2"
                    width="10px"
                    height="10px"
                />
            </div>
            <div class="flex-grow">
                <ClientOnly>
                    <template #fallback>
                        <!-- this will be rendered on server side -->
                        <div
                            class="flex items-center justify-center rounded-xl bg-gray-100 text-sm font-semibold text-gray-400"
                            :class="toggleMode ? 'h-10' : 'h-[84px]'"
                        >
                            Loading...
                        </div>
                    </template>
                    <InputEditor
                        v-model="html"
                        ref="$editorWraper"
                        placeholder="Comentar..."
                        :toggle-mode="toggleMode"
                        :editor-id="'comment-editor' + (isMain ? '-main' : '')"
                    />
                </ClientOnly>
            </div>
        </div>
        <transition name="slide-down">
            <div class="make-comment-input__action">
                <button class="make-comment-input__button" @click="submit">
                    <img
                        v-show="replying"
                        :src="$assets.white.loading"
                        alt="icon"
                        class="spin inline-block h-5 w-5"
                    />
                    <span v-show="!replying">{{ $lang.components.formMakeComment.answer }}</span>
                </button>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

const props = defineProps({
    user: Object as PropType<Models.User>,
    isMain: Boolean,
    toggleMode: {
        type: Boolean,
        default: false,
    },
    replying: Boolean,
    replyCallback: {
        type: Function as PropType<(html: string) => Promise<any>>,
        required: true,
    },
})

const emits = defineEmits(['reply'])

const $editorWraper = ref()

const html = ref('')

const sending = ref(false)

const submit = async () => {
    if (!$editorWraper.value.hasFocus) {
        $editorWraper.value.focus()
    } else if (!sending.value) {
        sending.value = true

        const success = await props.replyCallback(html.value)

        if (success) {
            sending.value = false
            $editorWraper.value.clear()
        } else {
            sending.value = false
        }
    }
}
</script>

<style lang="postcss" scoped>
.make-comment-input {
    @apply relative z-20 flex w-full flex-col space-y-2;
    &__form {
        @apply flex w-full lg:space-x-2;
    }
    &__icon {
        @apply hidden h-10 w-10 flex-none justify-center rounded-full border bg-gray-200 shadow-md lg:block;
        img {
            @apply h-full w-full;
        }
    }
    &__action {
        @apply flex lg:ml-12;
    }
    &__button {
        @apply h-7 w-24 rounded-full bg-site-primary text-center text-sm text-white shadow-md;
    }
}
</style>
