<template>
    <transition name="slide-down">
        <div v-if="info.discount && showResume" class="layout-header-floating-resume">
            <div class="layout-header-floating-resume__container">
                <DWidgetHeat
                    v-if="$config.public.variant === 'megadescuentos'"
                    :count="info.discount.temperature_sum || 0"
                />
                <p class="layout-header-floating-resume__title">{{ info.discount.title }}</p>
                <div class="layout-header-floating-resume__button">
                    <ButtonDiscount
                        :info="{
                            inResume: true,
                            slug: info.discount.slug,
                            code: info.discount.code || '',
                            mode: info.mode,
                            showCode: true,
                            openCoupon,
                            discount: info.discount,
                            openOffer,
                        }"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<{
            discount: Models.Discount
            mode: 'offer' | 'offer-code' | 'coupon' | 'no-link-offer' | 'no-link-coupon' | ''
        }>,
        required: true,
    },
})

const showResume = ref(false)
const listener = ref(null) as Ref<null | (() => void)>

onMounted(() => {
    let lastScrollY = scrollY

    listener.value = () => {
        showResume.value = lastScrollY <= scrollY && scrollY >= 600

        lastScrollY = window.scrollY
    }

    document.addEventListener('scroll', listener.value)
})
onUnmounted(() => {
    listener.value && document.removeEventListener('scroll', listener.value)
})

const { $openers } = useNuxtApp()

const openCoupon = () => {
    $openers.openCoupon(props.info.discount.slug)
}
const openOffer = () => {
    $openers.openOffer(props.info.discount.id)
}
</script>

<style lang="postcss" scoped>
.layout-header-floating-resume {
    @apply fixed left-0 right-0 top-0 z-50 h-14 bg-white shadow-lg lg:h-16;
    &__container {
        @apply container flex h-full max-w-5xl items-center justify-between space-x-2.5 px-2 lg:justify-start lg:space-x-5;
    }
    &__counter {
        @apply flex-none;
    }
    &__title {
        @apply line-clamp-2 flex-grow text-xs font-semibold md:text-lg;
        @apply leading-[1.2];
    }
    &__button {
        @apply max-w-56;
    }
}
</style>
