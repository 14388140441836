<template>
    <div class="editor-component">
        <div v-if="toggleMode" v-show="!hasFocus" @mousedown="focus" class="editor-component__skeleton">
            {{ placeholder }}
        </div>
        <div
            v-else
            v-show="!editor"
            class="flex h-[84px] items-center justify-center rounded-xl bg-gray-100 text-sm font-semibold text-gray-400"
        >
            Loading...
        </div>

        <div class="editor-component__wrapper" v-if="editor" v-show="(toggleMode && hasFocus) || !toggleMode">
            <EditorContent :editor="editor" />
            <div class="editor-component__controls">
                <button
                    type="button"
                    @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }"
                >
                    <svg width="24" height="24" focusable="false">
                        <path
                            d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 0 1-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8Zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4Zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4Z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>
                <button
                    type="button"
                    @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }"
                >
                    <svg width="24" height="24" focusable="false">
                        <path
                            d="m16.7 4.7-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8Z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>
                <button
                    type="button"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }"
                >
                    <svg width="24" height="24" focusable="false">
                        <path
                            d="M11 5h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2ZM4.5 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1Zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1Zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1Z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>
                <button
                    type="button"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editor.isActive('orderedList') }"
                >
                    <svg width="24" height="24" focusable="false">
                        <path
                            d="M10 17h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 1 1 0-2ZM6 4v3.5c0 .3-.2.5-.5.5a.5.5 0 0 1-.5-.5V5h-.5a.5.5 0 0 1 0-1H6Zm-1 8.8.2.2h1.3c.3 0 .5.2.5.5s-.2.5-.5.5H4.9a1 1 0 0 1-.9-1V13c0-.4.3-.8.6-1l1.2-.4.2-.3a.2.2 0 0 0-.2-.2H4.5a.5.5 0 0 1-.5-.5c0-.3.2-.5.5-.5h1.6c.5 0 .9.4.9 1v.1c0 .4-.3.8-.6 1l-1.2.4-.2.3ZM7 17v2c0 .6-.4 1-1 1H4.5a.5.5 0 0 1 0-1h1.2c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.4a.4.4 0 1 1 0-.8h1.3c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H4.5a.5.5 0 1 1 0-1H6c.6 0 1 .4 1 1Z"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>
                <button type="button" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                    <svg width="24" height="24" focusable="false">
                        <path
                            d="M6.2 12.3a1 1 0 0 1 1.4 1.4l-2 2a2 2 0 1 0 2.6 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2Zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2Z"
                            fill="currentColor"
                            fill-rule="nonzero"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Editor, EditorContent } from '@tiptap/vue-3'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'

const props = defineProps(['modelValue', 'placeholder', 'toggleMode', 'editorId'])

const emits = defineEmits(['update:modelValue'])

const hasFocus = ref(false)

const editor = ref(null) as Ref<Editor | null>

const clear = () => {
    hasFocus.value = false
    editor.value?.commands.clearContent(true)
}
const focus = () => {
    hasFocus.value = true

    nextTick(() => {
        editor.value?.commands.focus()
    })
}

defineExpose({
    clear,
    focus,
    hasFocus,
})

const setLink = () => {
    const previousUrl = editor.value?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
        return
    }

    if (url === '') {
        editor.value?.chain().focus().extendMarkRange('link').unsetLink().run()

        return
    }

    editor.value?.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
}

onMounted(() => {
    editor.value = new Editor({
        extensions: [
            TextStyle.configure({}),
            StarterKit,
            Link.configure({
                openOnClick: false,
                defaultProtocol: 'https',
            }),
        ],
        content: props.modelValue,
        onBlur: () => {
            if (editor.value?.getHTML() === '<p></p>') {
                hasFocus.value = false
            }
        },
        onUpdate: () => {
            nextTick(() => {
                const html = editor.value?.getHTML()

                emits('update:modelValue', html === '<p></p>' ? '' : html)
            })
        },
        editorProps: {
            attributes: {
                class: 'editor-component__tiptap',
            },
        },
    })
})
</script>

<style lang="postcss">
.editor-component {
    &__wrapper {
        @apply space-y-2 rounded-md border border-gray-200 p-2;
    }
    &__skeleton {
        @apply h-10 rounded-lg bg-gray-100 indent-4 text-sm leading-10 text-gray-400;
    }
    &__controls {
        @apply flex gap-3 rounded-md;

        button {
            @apply rounded border border-transparent bg-gray-100 px-1 py-0.5 text-sm;
            svg {
                @apply block h-6 w-6;
            }
            &.is-active {
                @apply bg-site-primary text-site-primary-text-contrast;
                svg {
                    @apply fill-current;
                }
            }
        }
    }
    &__tiptap {
        @apply prose prose-sm w-full bg-white sm:prose focus:outline-none;
        @apply max-w-full;
        * {
            @apply m-0 mb-2;
        }
    }
}
</style>
