<template>
    <div v-if="comment.user" class="card-comment-thread">
        <CCardComment :comment="comment" @reply="concatReply" />

        <button
            v-if="children && comment.comments_count > children.length && show.loadComments"
            type="button"
            class="card-comment-thread__load-comments"
            :disabled="disable.loadComments"
            @click="loadComments"
        >
            {{ $lang.components.cardCommentThread.load_more_answers }}
        </button>

        <ul
            v-if="children && children.length > 0"
            class="card-comment-thread__replies"
            :class="children && children.length > 0 && show.loadComments ? 'mt-1' : 'mt-2'"
        >
            <li v-for="(item, index) in children" :key="index + 'loaded'">
                <CCardComment :comment="item" :parent-id="comment.id" />
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import type { Models } from '~/types/models'

import type { Api, ServicesQueryParameters } from '~~/global'

const props = defineProps({
    comment: {
        type: Object as PropType<Models.Comment>,
        required: true,
    },
})

const { $endpoints } = useNuxtApp()

const show = reactive({
    loadComments: true,
})
const disable = reactive({
    loadComments: false,
})
const nextPageQuery = reactive({
    child_of: props.comment.id,
    per_page: 6,
    page: 1,
    order_by: 'latest',
}) as ServicesQueryParameters.GetComments

const children = ref([]) as Ref<Models.Comment[]>

watch(
    props.comment,
    (comment) => {
        children.value = comment.comments ? [...comment.comments].reverse() : []
    },
    { deep: true, immediate: true },
)
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const loadComments = async () => {
    disable.loadComments = true

    const commentsResult = await $fetch<Api.Responses.General.Comments>(endpoints.general.comments.path, {
        headers: buildHeaders(),
        params: nextPageQuery,
        baseURL,
    })

    if (commentsResult.feedback === 'data_success') {
        if (commentsResult.data.meta.current_page === 1) {
            children.value = [...commentsResult.data.results].reverse()
        } else {
            children.value = [...children.value, ...commentsResult.data.results].reverse()
        }

        if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
            nextPageQuery.page = commentsResult.data.meta.current_page + 1
        } else {
            show.loadComments = false
        }
    }
    disable.loadComments = false
}

const concatReply = (reply: Models.Comment) => {
    children.value = [...children.value, reply]
}
</script>

<style lang="postcss" scoped>
.card-comment-thread {
    @apply relative rounded;
    &__load-comments {
        @apply mt-2 text-xs font-semibold leading-3 text-gray-800 hover:underline disabled:text-gray-800;
    }
    &__replies {
        @apply list-none space-y-2 pl-4 pt-1 lg:pl-8;
    }
}
</style>
