div
<template>
    <div :id="(parentId ? 'reply-' : 'comment-') + comment.id" class="comment-item">
        <!-- :class="{
            'active-pulses':
                $route.hash === (parentId ? '#reply-' : '#comment-') + comment.id || pulsesActivated,
        }" -->
        <div class="comment-item__wrapper shadow-pulse">
            <div v-if="comment.user" class="comment-item__header">
                <div class="comment-item__icon">
                    <NuxtLink no-prefetch :to="'/dashboard/' + comment.user.id" rel="follow">
                        <MiscUserPic class="h-8 w-8" :user="comment.user" :badge="false" />
                    </NuxtLink>
                </div>
                <div v-if="comment.user" class="comment-item__user">
                    <NuxtLink
                        no-prefetch
                        :to="'/dashboard/' + comment.user.id"
                        rel="follow"
                        class="user_name"
                    >
                        {{ comment.user.username }}
                    </NuxtLink>
                    <MiscBadge
                        v-if="comment.user.role && comment.user.role.name !== 'Usuario'"
                        :text="comment.user.role.name"
                    />
                    <MiscBadge v-else-if="comment.user.verified" :full-badge="false" />
                </div>

                <p class="comment-item__timestamp hidden xs:block">{{ timestamp }}</p>

                <div class="relative" :class="{ 'z-10': show.commentMenu }">
                    <button
                        ref="MaincardDropdown"
                        type="button"
                        class="comment-item__dropdown-button"
                        @click="() => commentMenuToggle(!show.commentMenu)"
                    >
                        <img :src="$assets.gray.dropdownMenuDots" alt="dropdown" width="6px" height="6px" />
                    </button>
                    <Transition name="drop-from-right">
                        <DropdownOptions
                            v-if="show.commentMenu"
                            class="absolute right-0 top-full w-48 rounded-xl"
                            :options="menuOptions"
                            @close="() => commentMenuToggle(false)"
                        />
                    </Transition>
                </div>
            </div>

            <div class="comment-item__content" v-html="removeXSS(setURLS(comment.content))" />

            <div class="comment-item__footer">
                <p v-if="timestamp" class="comment-item__timestamp xs:hidden">{{ timestamp }}</p>

                <div class="comment-item__likes">
                    <button
                        type="button"
                        class="comment-item__like-btn"
                        :disable="disable.like"
                        @click="handleLike"
                    >
                        {{ $lang.components.cardComment.like }}&nbsp;·&nbsp;
                    </button>

                    <p class="comment-item__like-count">
                        <button
                            type="button"
                            class="comment-item__like-sprite"
                            :disable="disable.like"
                            :class="{ 'is-liked': liked }"
                            :style="{ backgroundImage: 'url(' + $assets.illustration.likeSprite + ')' }"
                            @click="handleLike"
                        ></button>
                        <span>{{
                            comment.liked === true
                                ? comment.likes_count
                                : liked
                                  ? (comment.likes_count || 0) + 1
                                  : comment.likes_count
                        }}</span>
                    </p>
                </div>

                <span v-if="!parentId" class="comment-item__replies-likes-separator"></span>

                <div v-if="!parentId" class="comment-item__replies-elements">
                    <button
                        type="button"
                        class="comment-item__replies-btn"
                        @click="() => replyFormToggle(true)"
                    >
                        {{ $lang.components.cardComment.answer }}&nbsp;·&nbsp;
                    </button>

                    <p class="comment-item__replies-count">
                        {{ comment.comments_count }}&nbsp;
                        <span class="hidden sm:inline">{{ $lang.components.cardComment.responses }}</span>
                        <img class="inline sm:hidden" :src="$assets.gray.replies" alt="icon" />
                    </p>
                </div>
            </div>
        </div>

        <div ref="$input" v-if="show.replyForm">
            <CInputMakeComment
                class="lg:mt-2"
                :class="{
                    'grayscale filter': disable.commentForm,
                }"
                :replying="creatingNewComment"
                :user="UserData || undefined"
                :reply-callback="createComment"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import type { Models } from '~/types/models'
import { removeXSS, setURLS } from '~/util/textFunction'
import type { Api } from '~~/global'
import { useAuthStore } from '~/store/auth'

import { useRootStore } from '~/store/root'

const props = defineProps({
    comment: {
        type: Object as PropType<Models.Comment>,
        required: true,
    },
    parentId: Number,
})

const emits = defineEmits<{
    (e: 'reply', reply: Models.Comment): void
}>()

const $input = ref()

onClickOutside($input, () => (show.replyForm = false))

const { UserData, SessionToken, IsAuth, setAuthModal, updateActivitiesCount } = useAuthStore()
const { setSiteNotification, setSiteDropdown } = useRootStore()
const copyText = useClipboard()
const { $assets, $lang, $bp } = useNuxtApp()

const liked = ref(Boolean(props.comment.liked))

const show = reactive({
    replyForm: false,
    commentMenu: false,
    loadComments: true,
})

const disable = reactive({
    like: Boolean(props.comment.liked),
    loadComments: false,
    commentForm: false,
})

const timestamp = computed(() => {
    const timestamp =
        (props.comment.created_at &&
            formatDistanceToNowStrict(parseISO(props.comment.created_at), { locale })) ||
        ''
    const shortTimestamp = timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)

    return shortTimestamp
})
const menuOptions = computed(() => {
    return [
        {
            title: $lang.components.cardComment.copy_link_to_comment,
            icon: $assets.primary.referralLink,
            handler: () => {
                copyText.copy(
                    window.location.host +
                        window.location.pathname +
                        `#${props.parentId ? 'reply' : 'comment'}-${props.comment.id}`,
                )
            },
        },
        {
            title: $lang.components.cardComment.report,
            icon: $assets.primary.shield,
            description: $lang.components.cardComment.why_report,
            subDropdown: true,
            options: [
                {
                    title: $lang.components.cardComment.spam_offensive,
                    icon: $assets.primary.span,
                    handler: () => {
                        createReport('Spam/Ofensivo')
                    },
                },
                {
                    title: $lang.components.cardComment.abuse_hate,
                    icon: $assets.gray.dislike,
                    handler: () => {
                        createReport('Acoso o incitación al odio')
                    },
                },
            ],
        },
    ]
})

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const createReport = async (reason: Parameters<Api.Services['create']['postReport']>[0]['reason']) => {
    if (!IsAuth) {
        setAuthModal({
            type: 'sign-in',
        })
        return
    }

    if (!UserData?.verified_email) {
        setAuthModal({
            type: 'email-verification',
            info: {
                isReminder: true,
                message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_report} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
            },
        })
        return
    }

    try {
        const reportResult = await $fetch<
            Api.Responses.Actions.SuccessReport | Api.Responses.Actions.FailedReport
        >(endpoints.create.report.path, {
            params: {
                reportable_type: 'comment',
                reportable_id: props.comment.id,
                reason,
            },
            headers: buildHeaders(SessionToken),
            baseURL,
            method: 'POST',
            onResponse() {
                updateActivitiesCount('notifications')
            },
        })

        if (reportResult && reportResult.feedback === 'data_success') {
            setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.components.cardComment.okay,
                text: $lang.components.cardComment.report_success,
                type: 'success',
            })
        } else {
            setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.components.cardComment.close,
                text: $lang.components.cardComment.report_failed,
                type: 'error',
            })
        }
    } catch (e) {}
}
const handleLike = async () => {
    if (!IsAuth) {
        setAuthModal({
            type: 'sign-in',
        })
        return
    }

    if (!UserData?.verified_email) {
        setAuthModal({
            type: 'email-verification',
            info: {
                isReminder: true,
                message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_likes} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
            },
        })
        return
    }

    disable.like = true

    try {
        const likeResult = await $fetch<Api.Responses.Actions.SuccessLike | Api.Responses.Actions.FailedLike>(
            endpoints.create.likes.path,
            {
                params: {
                    likeable_type: 'comment',
                    likeable_id: props.comment.id,
                    type: 1,
                },
                headers: buildHeaders(SessionToken),
                baseURL,
                method: 'POST',
                onResponse() {
                    updateActivitiesCount('notifications')
                },
            },
        )

        if (likeResult && likeResult.feedback === 'data_success') {
            liked.value = true
        }
    } catch (e) {
        if (e) {
        }
    }

    disable.like = true
}

const { creatingNewComment, createNewComment } = usePostActions()

const createComment = async (html: string) => {
    if (!IsAuth) {
        setAuthModal({
            type: 'sign-in',
        })
        return false
    }
    if (!UserData?.verified_email) {
        setAuthModal({
            type: 'email-verification',
            info: {
                isReminder: true,
                message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_comment} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
            },
        })
        return false
    }

    const success = await createNewComment({
        commentable_id: props.comment.id,
        commentable_type: 'comment',
        content: html,
    })

    show.replyForm = !success

    return success
}

const commentMenuToggle = (data: boolean) => {
    if ($bp.mb || $bp.xs || $bp.sm) {
        setSiteDropdown(data ? menuOptions.value : null)
    } else {
        show.commentMenu = data
    }
}
const replyFormToggle = (data: boolean) => {
    show.replyForm = data
}
</script>

<style lang="postcss" scoped>
.comment-item {
    &__wrapper {
        @apply relative mb-8 rounded-xl bg-gray-100 p-2 lg:mb-0;
    }
    &__header {
        @apply mb-2 flex items-center space-x-2 text-sm;
    }
    &__dropdown-button {
        @apply relative mt-1 h-4 w-4 self-start;
        img {
            @apply h-3 w-3;
        }
    }
    &__icon {
        @apply h-8 w-8 flex-none cursor-pointer;
        img {
            @apply h-full w-full rounded-full object-contain;
        }
    }
    &__user {
        @apply flex flex-grow flex-wrap items-center gap-x-1 leading-4;
        .user_name {
            @apply line-clamp-1 inline-block cursor-pointer font-semibold lg:hover:underline;
        }
        .labels {
            @apply flex w-fit space-x-1 self-start rounded-xl border border-gray-50 bg-white p-0.5 px-1 text-xs capitalize shadow-md;
            img {
                @apply h-4 w-4;
            }
        }
    }
    &__timestamp {
        @apply text-xs text-gray-800 xs:text-[10px];
    }
    &__footer {
        @apply absolute left-0 top-full flex w-full items-center space-x-1 pt-1 text-xs font-medium text-gray-800 lg:static lg:border-t;
    }
    &__likes {
        @apply flex items-center;
    }
    &__like-btn {
        @apply font-semibold filter disabled:grayscale lg:hover:underline;
    }
    &__like-sprite {
        @apply block h-4 w-4 scale-[250%] transform bg-no-repeat filter disabled:grayscale;
        background-position: 0 0;
        background-size: auto 100%;
        cursor: pointer;
        transition: background-position 1s steps(28);

        &.is-liked {
            background-position: 100% 50%;
        }
    }
    &__like-count,
    &__replies-count {
        @apply flex space-x-1;
        img {
            @apply h-4 w-4;
        }
    }
    &__replies-elements {
        @apply flex items-center;
    }
    &__replies-btn {
        @apply font-semibold lg:hover:underline;
    }
    &__replies-likes-separator {
        @apply block h-4 w-px flex-none bg-gray-200;
    }
    &.active-pulses {
        .shadow-pulse {
            @apply border shadow-md shadow-site-primary-shadow;
            animation-name: shadow-pulse;
            animation-iteration-count: 5;
            animation-duration: 1s;
            animation-delay: 1s;
        }

        @keyframes shadow-pulse {
            0%,
            100% {
                @apply border-transparent;
            }
            50% {
                @apply border-site-primary;
            }
        }
    }
}
</style>
<style lang="postcss" scoped>
.comment-item {
    &__content {
        @apply prose prose-gray mb-2 max-w-full overflow-hidden leading-6;
        :deep(a) {
            @apply font-semibold text-site-primary;
        }
        * {
            @apply mb-1 mt-0;
        }
    }
}
</style>
